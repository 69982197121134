import { fetchAuthSession } from '@aws-amplify/auth';
import * as CDM from '@plugsurfing/cdm-api-client';
import mapValues from 'lodash/mapValues';
import { CDMError } from 'services/CDMError';
import { CDM_URL, userProfile } from '../config/constants';

// @deprecated
class CDMService {
  /* eslint-disable @typescript-eslint/member-ordering */
  private readonly _authenticatedFetch = async (input: RequestInfo | URL, init?: RequestInit) => {
    /* DISABLE FOR NOW
      const user = await Auth.currentAuthenticatedUser();
      if (user === undefined) {
        throw new CDMError(
          `Can't make an authenticated request since the user pool has no authenticated user`,
          [],
          401,
        );
      }
      const credentials = await Auth.currentUserCredentials();
      const isValid = credentials.authenticated;
      if (!isValid) {
        throw new CDMError('Invalid Session', [], 403);
      }
      */
    const isLocal = CDM_URL.indexOf('localhost') > 0;
    if (isLocal) {
      const tokenPayload = await this.getTokenPayload();
      return this.fetchLocal(input, init, tokenPayload);
    } else {
      const response = await this._fetchWrapper(input, init);
      if (response.status === 401) {
        location.reload();
      }
      return response;
    }
  };

  private readonly _fetchWrapper: typeof fetch = async (
    input: RequestInfo | URL,
    { headers: inputHeaders, ...init }: RequestInit = {},
  ) => {
    const headers = new Headers(inputHeaders);
    try {
      const session = await fetchAuthSession();
      if (session.tokens?.idToken) {
        headers.set('Authorization', session.tokens?.idToken?.toString());
      }
    } catch (error) {
      // Fallback to vanilla fetch
    }
    return fetch(input, { headers, ...init });
  };

  readonly lowLevelFetch = async (input: RequestInfo, init?: RequestInit) => this._authenticatedFetch(input, init);

  async fetchLocal(
    input: RequestInfo | URL,
    { headers: inputHeaders, ...init }: RequestInit = {},
    tokenPayload: { sub: string; userId: string },
  ): Promise<Response> {
    const headers = new Headers(inputHeaders);
    headers.set('cd-claims-cognito-username', tokenPayload.sub);
    headers.set('cd-claims-user-id', tokenPayload.userId);
    return fetch(input, { headers, ...init });
  }

  getTokenPayload() {
    return fetchAuthSession()
      .then(({ tokens }) => tokens?.idToken?.payload)
      .catch(err => err);
  }

  private readonly basePath = CDM_URL;
  readonly chargePointAndConnectorStatusApi = this._instantiateApi(CDM.createChargepointAndConnectorStatusApi);
  readonly chargePointClient = this._instantiateApi(CDM.createChargePointControllerApi);
  readonly chargePointModelClient = this._instantiateApi(CDM.createChargePointModelControllerApi);
  readonly chargePointSiteClient = this._instantiateApi(CDM.createChargePointLocationApi);
  readonly chargePointLocationClient = this._instantiateApi(CDM.createChargePointLocationApi);
  readonly chargingSessionsClient = this._instantiateApi(CDM.createChargingSessionsApi);
  readonly chargingSessionClassificationClient = this._instantiateApi(CDM.createChargingSessionClassificationApi);
  readonly firmwareClient = this._instantiateApi(CDM.createFirmwareControllerApi);
  readonly connectionProfileClient = this._instantiateApi(CDM.createConnectionProfileControllerApi);
  readonly organizationsV2Client = this._instantiateApi(CDM.createOrganizationsV2Api);
  readonly customerPaymentAccountsClient = this._instantiateApi(CDM.createCustomerPaymentAccountsApi);
  readonly empPaymentAccountsClient = this._instantiateApi(CDM.createEmpPaymentAccountsApi);
  readonly priceClient = this._instantiateApi(CDM.createPriceProfilesApi);
  readonly priceProfileAssociationClient = this._instantiateApi(CDM.createPriceAssociationsApi);
  readonly priceAssociationClient = this._instantiateApi(CDM.createPriceAssociationsApi);
  readonly remoteCommandClient = this._instantiateApi(CDM.createRemoteCommandControllerApi);
  readonly rolesClient = this._instantiateApi(CDM.createRolesApi);
  readonly systemClient = this._instantiateApi(CDM.createSystemApi);
  readonly usersClient = this._instantiateApi(CDM.createUsersApi);
  readonly usersClientV2 = this._instantiateApi(CDM.createUsersV2Api);
  readonly chargePointSessionHistoryV2Client = this._instantiateApi(CDM.createSessionHistoryV2Api);
  readonly customerKeysOrdersClient = this._instantiateApi(CDM.createCustomerKeysOrdersApi);
  readonly notificationProfilesClient = this._instantiateApi(CDM.createNotificationControllerApi);
  readonly chargingKeysClient = this._instantiateApi(CDM.createChargingKeysApi);
  readonly invoiceClient = this._instantiateApi(CDM.createInvoicesApi);
  readonly invoiceItemClient = this._instantiateApi(CDM.createInvoiceItemsControllerApi);
  readonly receiptClient = this._instantiateApi(CDM.createReceiptsApi);
  readonly campaignsClient = this._instantiateApi(CDM.createCampaignsApi);
  readonly issuesClient = this._instantiateApi(CDM.createIssueTrackerControllerApi);
  readonly assetReportsClient = this._instantiateApi(CDM.createAssetReportsControllerApi);
  readonly evseClient = this._instantiateApi(CDM.createEvseOrganizationControllerApi);
  readonly reportingClient = this._instantiateApi(CDM.createOperationsReportingControllerApi);
  readonly publishingChannelClient = this._instantiateApi(CDM.createPublishingChannelControllerApi);
  readonly externalTicketsClient = this._instantiateApi(CDM.createExternalTicketTrackerControllerApi);
  readonly chargePointBulkTemplateClient = this._instantiateApi(CDM.createChargePointBulkCreateTemplateControllerV2Api);
  readonly chargePointBulkOperationsClient = this._instantiateApi(CDM.createChargePointBulkOperationsControllerV2Api);
  readonly connectorGroupClient = this._instantiateApi(CDM.createConnectorGroupControllerApi);
  readonly externalConnectorGroupClient = this._instantiateApi(CDM.createExternalConnectorGroupsApi);
  readonly firmwareErrorCodeClient = this._instantiateApi(CDM.createFirmwareErrorCodeControllerApi);
  readonly diagnosticsClient = this._instantiateApi(CDM.createChargePointDiagnosticsControllerApi);
  readonly partnerClient = this._instantiateApi(CDM.createPartnerManagementControllerApi);
  readonly masterDataClient = this._instantiateApi(CDM.createMasterDataApi);
  readonly smartChargingClient = this._instantiateApi(CDM.createSmartChargingConfigurationControllerApi);
  readonly debtClient = this._instantiateApi(CDM.createDebtV1Api);
  readonly userGroupsClient = this._instantiateApi(CDM.createUserGroupsApi);
  readonly dashboardClient = this._instantiateApi(CDM.createDashboardsApi);
  readonly customersV2Client = this._instantiateApi(CDM.createCustomersV2Api);
  readonly priceSimulatorClient = this._instantiateApi(CDM.createPriceSimulatorApi);
  readonly templatesClient = this._instantiateApi(CDM.createTemplatesApi);
  readonly subscriptionsClient = this._instantiateApi(CDM.createSubscriptionsApi);
  readonly organizationOrdersClient = this._instantiateApi(CDM.createOrganizationRegistrationsApi);
  readonly productsClient = this._instantiateApi(CDM.createProductControllerApi);
  readonly adHocClient = this._instantiateApi(CDM.createAdHocControllerApi);
  readonly organizationsConfigClient = this._instantiateApi(CDM.createOrganizationsConfigControllerApi);
  readonly s3SignedUrlsClient = this._instantiateApi(CDM.createS3SignedUrlsApi);
  readonly externalChargePointSiteClient = this._instantiateApi(CDM.createExternalLocationControllerApi);
  readonly aggregatedStatusClient = this._instantiateApi(CDM.createAggregatedConnectorStatusControllerApi);
  readonly stripePayoutClient = this._instantiateApi(CDM.createStripePayoutsApi);

  private _instantiateApi<T extends object>(createApi: (baseUrl: string, fetchWrapper: typeof fetch) => T): T {
    return mapValues(
      createApi(`${this.basePath}${userProfile.profileResource}`, this._authenticatedFetch) as any,
      interceptPromiseRejection,
    ) as T;
  }
}

function interceptPromiseRejection<Args extends any[], T>(fn: (...args: Args) => T) {
  return (...args: Args) => Promise.resolve(fn(...args)).catch(convertToCDMError);
}

function throwError(error: unknown) {
  throw error;
}

function convertToCDMError(error: Response | unknown) {
  if (error instanceof Response) {
    return CDMError.fromResponse(error).then(throwError);
  } else {
    return Promise.reject(error);
  }
}

export default new CDMService();
