import {
  Flex,
  TableFooter,
  TableGroup,
  TableGroupProps,
  TableHeader,
  useCallbackRef,
  usePrevious,
} from '@plugsurfing/plugsurfing-design';
import CdBulkActionPopup, { BulkAction } from 'components/design-elements/CdTable/anatomy/CdBulkActionPopup';
import { ColumnSizesContext } from 'components/design-elements/CdTable/anatomy/CdColGroups';
import { getStickyColumnCount } from 'components/design-elements/CdTable/anatomy/helpers';
import { usePersistedTableOptions } from 'components/design-elements/CdTable/anatomy/usePersistedTableOptions';
import isEqual from 'lodash/isEqual';
import {
  ForwardedRef,
  ReactNode,
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  type ComponentProps,
} from 'react';
import { useSelector } from 'react-redux';
import { IdType, TableInstance, UseTableRowProps } from 'react-table';
import { selectSelf, selectUserRoles } from 'redux/users/selectors';
import { useIsInViewport } from 'utils/hooks/useIsInViewport';
import { canUserAccess } from 'utils/roles';
import { LayoutContainerContext } from 'views/LayoutContainer';
import type { CdTableHoverActionsModel } from './anatomy/CdTableHoverActions';
import CdTablePager, { DEFAULT_PAGE_SIZE } from './anatomy/CdTablePager';
import { CdTableRow, RowClickActionFactory } from './anatomy/CdTableRow';
import { CdTableTemplate, SideComponentRenderer, type CdTableTemplateProps } from './anatomy/CdTableTemplate';
import { Column, ExtraColumnOptions, getColumnId, useColumns } from './anatomy/useColumns';
import useInstance from './anatomy/useInstance';
import { RowSelectionType } from './anatomy/usePluginsForRowSelect';

export interface CdTableBaseProps<T extends object> extends TableGroupProps {
  columns: Array<Column<T>>;
  data?: T[];
  sortable?: boolean;
  loading?: boolean;
  error?: unknown;
  SubComponent?: SubComponentRenderer<T>;
  SideComponent?: SideComponentRenderer<T>;
  hoverActions?: (data: UseTableRowProps<T>) => CdTableHoverActionsModel;
  bulkActions?: BulkAction<T>[];
  /**
   * Type of row selection.
   * If not specified _and_ there are available bulk actions, the value is set to `multiple`, otherwise `off`.
   */
  selection?: RowSelectionType;
  /**
   * If set to true, checkbox or radio button for row selection is not shown, but selecting rows is still possible.
   * If row selection is disabled, this has no effect. Default: `false`
   */
  hideSelectionControl?: boolean;
  initialSelection?: Record<IdType<T>, boolean>;
  initialExpanded?: Record<IdType<T>, boolean>;
  filters?: ReactNode;
  pageIndex?: number;
  pageSize?: number;
  maxPageSize?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;
  totalItems?: number;
  hiddenColumns?: IdType<T>[];
  noDataText?: string;
  /**
   * Pagination type.
   *
   * - `always`: Pagination footer is always visible regardless of how many rows there are.
   * - `auto`: Pagination footer is visible only when the number of rows is larger than `pageSize`.
   * - `never`: Pagination is unavailable.
   *
   * Default to `auto`.
   */
  paginationType?: 'always' | 'auto' | 'never';
  /**
   * The number of columns stick to the left when the table scrolls horizontally.
   * The default value is decided automatically depending on whether rows have checkbox or expander.
   */
  frozenColumns?: number;
  canExpand?: (rowModel: T) => boolean;
  rowClickAction?: RowClickActionFactory<T> | null;
  getId?: (rowModel: T) => string;
  defaultSortBy?: IdType<T>;
  defaultSortOrderDesc?: boolean;
  onSetPageSize?: (size: number) => void;
  onPrev?: () => void;
  onNext?: () => void;
  onSelectedChange?: (selection: Record<IdType<T>, boolean>) => void;
  onExpandedChange?: (expanded: Record<IdType<T>, boolean>) => void;
  onSortedChange?(sort: Array<{ id: IdType<T>; desc?: boolean }>): void;
  onTryAgain?: () => void;
  onSetColumnSizes: (sizes?: Record<string, number>) => void;
}

export type SubComponentRenderer<T extends object> = (data: UseTableRowProps<T>) => ReactNode;

export interface CdTableRef<T> {
  getCurrentSelection(): T[];
  resetSelection(): void;
}

function useChangeListener<T>(value: T, listener?: (value: T) => unknown) {
  const prevValue = usePrevious(value);
  const listenerRef = useCallbackRef(listener);

  useEffect(() => {
    if (prevValue !== undefined && prevValue !== value) {
      listenerRef(value);
    }
  }, [value, prevValue, listenerRef]);
}

function createRowClickAction<T extends object>(
  instance: TableInstance<T>,
  columns: Column<T>[],
  hasExpand: boolean,
  canExpand: (model: T) => boolean,
  hiddenColumns?: IdType<T>[],
) {
  if (hasExpand) {
    return (model: T, rowId: IdType<T>) => (canExpand(model) ? () => instance.toggleRowExpanded([rowId]) : undefined);
  }

  for (const col of columns) {
    if ('link' in col && col.isPrimary && !(hiddenColumns ?? []).includes(getColumnId(col))) {
      return (model: T) => {
        const link = col.link(model);

        return typeof link === 'string' ? undefined : link;
      };
    }
  }
}

function createRowComponent<T extends object>(
  extraColumnOptions: Map<IdType<T>, ExtraColumnOptions>,
  stickyColumnCount: number,
  SubComponent?: SubComponentRenderer<T>,
  rowClickAction?: RowClickActionFactory<T>,
) {
  function TableRowWrapper({
    table,
    row,
    containerRef,
    tableRef,
    loading,
  }: ComponentProps<CdTableTemplateProps<T>['RowComponent']>) {
    const trRef = useRef<HTMLTableRowElement>(null);
    const isInViewport = useIsInViewport(trRef);

    table.prepareRow(row);

    const parent = table.rowsById[row.id.split('.')[0] ?? ''];

    return (
      <CdTableRow
        ref={trRef}
        // Since applying skeleton animation to a large number of rows can be slow, exclude rows outside of the viewport
        shouldApplySkeleton={loading && isInViewport}
        row={row}
        parent={parent}
        columnCount={table.visibleColumns.length}
        stickyColumnCount={stickyColumnCount}
        clickAction={rowClickAction}
        trackUpdate={[row.isSelected, row.isExpanded, parent?.isSelected].join('|')}
        extraColumnOptions={extraColumnOptions}
        SubComponent={SubComponent}
        containerRef={containerRef}
        tableRef={tableRef}
      />
    );
  }

  return TableRowWrapper;
}

const alwaysTrue = () => true;

const CdTableBaseWithoutRefForwarding = <T extends object>(
  {
    loading = false,
    error,
    data,
    columns: columnsProp,
    sortable = true,
    selection: selectionProp,
    initialSelection,
    hideSelectionControl = false,
    pageSize: pageSizeProp,
    maxPageSize,
    filters,
    pageIndex,
    hasPrevious,
    hasNext,
    totalItems,
    hiddenColumns,
    defaultSortBy,
    defaultSortOrderDesc,
    initialExpanded,
    noDataText,
    paginationType = 'auto',
    SubComponent,
    SideComponent,
    hoverActions,
    bulkActions,
    frozenColumns,
    getId,
    canExpand: canExpandProp = alwaysTrue,
    rowClickAction: rowClickActionProp,
    onPrev,
    onNext,
    onExpandedChange,
    onSelectedChange,
    onSortedChange,
    onSetPageSize,
    onTryAgain,
    onSetColumnSizes,
    ...tableGroupProps
  }: CdTableBaseProps<T>,
  ref: ForwardedRef<CdTableRef<T>>,
) => {
  const { isMobile } = useContext(LayoutContainerContext);
  const allRoles = useSelector(selectUserRoles());
  const self = useSelector(selectSelf);
  const allowedBulkActions = useMemo(() => {
    return isMobile
      ? []
      : bulkActions?.filter(action =>
          canUserAccess(self, allRoles.data, action.allowedPrivileges, action.allowedModules),
        ) ?? [];
  }, [allRoles.data, bulkActions, isMobile, self]);

  const pageSize = Math.max(1, paginationType === 'never' ? data?.length ?? 0 : pageSizeProp ?? DEFAULT_PAGE_SIZE);
  const manualPagination = pageIndex !== undefined;
  const manualSort = onSortedChange !== undefined;
  const hasExpand = SubComponent !== undefined;
  const [columns, extraColumnOptions] = useColumns(columnsProp, hasExpand, hoverActions);
  const canExpand = useCallback((model: T) => hasExpand && canExpandProp(model), [canExpandProp, hasExpand]);
  const shouldShowPagination =
    paginationType === 'always' ||
    (paginationType === 'auto' &&
      (pageSize !== DEFAULT_PAGE_SIZE || (manualPagination ? hasNext : (data?.length ?? 0) > pageSize)));
  const selection = selectionProp === undefined ? (allowedBulkActions.length > 0 ? 'multiple' : 'off') : selectionProp;

  const instance = useInstance<T>(
    columns,
    data,
    manualSort,
    manualPagination,
    sortable,
    selection,
    hideSelectionControl,
    canExpand,
    getId,
    {
      pageSize,
      ...(initialExpanded ? { expanded: initialExpanded } : {}),
      pageIndex: pageIndex ?? 0,
      hiddenColumns: hiddenColumns ?? [],
      sortBy: defaultSortBy === undefined ? [] : [{ id: defaultSortBy, desc: defaultSortOrderDesc }],
      ...(initialSelection === undefined ? {} : { selectedRowIds: initialSelection }),
    },
  );
  const selectedRows = useMemo(() => instance.selectedFlatRows.map(r => r.original), [instance.selectedFlatRows]);
  const stickyColumnCount = useMemo(
    () => frozenColumns ?? getStickyColumnCount(isMobile, instance),
    [frozenColumns, instance, isMobile],
  );
  const rowClickAction = useMemo(
    () =>
      rowClickActionProp === null
        ? undefined
        : rowClickActionProp ?? createRowClickAction(instance, columnsProp, hasExpand, canExpand, hiddenColumns),
    [instance, columnsProp, hasExpand, canExpand, hiddenColumns, rowClickActionProp],
  );
  const RowComponent = useMemo(
    () => createRowComponent(extraColumnOptions, stickyColumnCount, SubComponent, rowClickAction),
    [SubComponent, extraColumnOptions, rowClickAction, stickyColumnCount],
  );
  const resetSelection = useCallback(() => instance.toggleAllRowsSelected(false), [instance]);

  useChangeListener(instance.state.pageSize, onSetPageSize);
  useChangeListener(instance.state.sortBy, onSortedChange);
  useChangeListener(instance.state.selectedRowIds, onSelectedChange);
  useChangeListener(instance.state.expanded, onExpandedChange);

  useImperativeHandle(ref, () => ({
    getCurrentSelection: () =>
      instance.selectedFlatRows.flatMap(row => ((row.original as any).__isSubRow ? [] : [row.original])),
    resetSelection: () => instance.toggleAllRowsSelected(false),
  }));

  useEffect(() => {
    if (pageSize !== instance.state.pageSize) {
      instance.setPageSize(pageSize);
    }
  }, [pageSize, instance]);

  useEffect(() => {
    const newColumns = hiddenColumns ?? [];

    if (!isEqual(newColumns, instance.state.hiddenColumns)) {
      instance.setHiddenColumns(newColumns);
    }
  }, [hiddenColumns, instance]);

  return (
    <TableGroup {...tableGroupProps}>
      {filters !== undefined && (
        <TableHeader overflow="auto" bg="white">
          <Flex
            flexDir={{ base: 'column', md: 'row' }}
            gap="m"
            alignItems={{ base: 'stretch', md: 'center' }}
            flexWrap="wrap"
          >
            {filters}
          </Flex>
        </TableHeader>
      )}
      <CdTableTemplate
        instance={instance}
        extraColumnOptions={extraColumnOptions}
        RowComponent={RowComponent}
        SideComponent={SideComponent}
        loading={loading}
        error={error}
        noDataText={noDataText}
        onTryAgain={onTryAgain}
        onSetColumnSizes={onSetColumnSizes}
        stickyColumnCount={stickyColumnCount}
        disableTopBorderRadius={filters !== undefined}
        disableBottomBorderRadius={!!shouldShowPagination}
      />
      {shouldShowPagination && (
        <TableFooter bg="white">
          <CdTablePager
            pageIndex={pageIndex ?? instance.state.pageIndex}
            pageSize={instance.state.pageSize}
            maxPageSize={maxPageSize}
            hasNext={hasNext ?? instance.canNextPage}
            hasPrevious={hasPrevious ?? instance.canPreviousPage}
            isLoading={loading ?? false}
            onSetPageSize={instance.setPageSize}
            onNext={onNext ?? instance.nextPage}
            onPrev={onPrev ?? instance.previousPage}
            totalItems={manualPagination ? totalItems : data?.length}
          />
        </TableFooter>
      )}
      <CdBulkActionPopup handleClosePopup={resetSelection} actions={allowedBulkActions} selection={selectedRows} />
    </TableGroup>
  );
};

export const CdTableBase = memo(forwardRef(CdTableBaseWithoutRefForwarding)) as <T extends object>(
  props: CdTableBaseProps<T> & { ref?: ForwardedRef<CdTableRef<T>> },
) => JSX.Element;

export interface CdTableProps<T extends object> extends Omit<CdTableBaseProps<T>, 'pageSize' | 'onSetColumnSizes'> {
  /**
   * The key used for storing user's preference (column sizes, page size etc.) in local storage.
   *
   * It needs to be __stable__ (i.e. doesn't change across user's sessions or code releases) and also
   * __unique__ (i.e. different table should have different key).
   */
  storageKey: string;
}

export default forwardRef(
  <T extends object>({ storageKey, onSetPageSize, ...props }: CdTableProps<T>, ref: ForwardedRef<CdTableRef<T>>) => {
    const [settings, setSettings] = usePersistedTableOptions(storageKey);
    const handleSetPageSize = useCallback(
      (pageSize: number) => {
        setSettings({ pageSize });
        onSetPageSize?.(pageSize);
      },
      [setSettings, onSetPageSize],
    );
    const handleColumnResize = useCallback(
      (columnSizes?: Record<string, number>) => setSettings({ columnSizes }),
      [setSettings],
    );

    return (
      <ColumnSizesContext.Provider value={settings.columnSizes}>
        <CdTableBase<T>
          pageSize={settings.pageSize}
          {...props}
          ref={ref}
          onSetPageSize={handleSetPageSize}
          onSetColumnSizes={handleColumnResize}
        />
      </ColumnSizesContext.Provider>
    );
  },
) as <T extends object>(props: CdTableProps<T> & { ref?: ForwardedRef<CdTableRef<T>> }) => JSX.Element;
