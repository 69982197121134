import { Box, Divider } from '@plugsurfing/plugsurfing-design';
import CdPageSection, { CdPageSectionProps } from 'components/design-elements/CdPageSection';
import { Loading } from 'components/general';
import { memo } from 'react';
import { p3Theme } from 'styles/theme';

export interface CdCardProps extends CdPageSectionProps {
  loading?: boolean;
  variant?: 'card' | 'divider';
}

export const CARD_DOM_ATTRIBUTE = 'data-cd-card';

const CdCard = memo(({ children, loading, variant = 'card', ...pageSectionProps }: CdCardProps) => {
  return (
    <CdPageSection
      {...pageSectionProps}
      headerPropsOverrides={variant === 'card' ? undefined : { alignItems: 'center', mb: 's' }}
    >
      <Box
        bg="background.primary"
        py={variant === 'card' ? 'm' : 0}
        px={variant === 'card' ? 'xl' : undefined}
        border={variant === 'card' ? `${p3Theme.borderWidths.s} solid ${p3Theme.colors.border.primary}` : undefined}
        borderRadius="m"
        display="flex"
        flexDirection="column"
        gap="m"
        position="relative"
        overflow={loading ? 'hidden' : undefined}
        minH={loading ? '120px' : undefined}
        {...{ [CARD_DOM_ATTRIBUTE]: true }}
      >
        {variant === 'divider' && <Divider />}
        {children}
        {loading && <Loading />}
      </Box>
    </CdPageSection>
  );
});

export default CdCard;
