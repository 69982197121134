import { cdmApi as api } from 'redux/slices/cdmApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    createOrganizationUsingPost: build.mutation<
      CreateOrganizationUsingPostApiResponse,
      CreateOrganizationUsingPostApiArg
    >({
      query: queryArg => ({
        url: `/v2/organizations`,
        method: 'POST',
        body: queryArg.organizationCreateRequest,
        params: { orderId: queryArg.orderId },
      }),
    }),
    exportPayoutsUsingPost: build.mutation<ExportPayoutsUsingPostApiResponse, ExportPayoutsUsingPostApiArg>({
      query: queryArg => ({
        url: `/v2/organizations/exports/reports/payouts`,
        method: 'POST',
        body: queryArg.payoutReportRequest,
      }),
    }),
    getOrganizationModulesUsingGet: build.query<
      GetOrganizationModulesUsingGetApiResponse,
      GetOrganizationModulesUsingGetApiArg
    >({
      query: () => ({ url: `/v2/organizations/modules` }),
    }),
    getOrderByIdUsingGet: build.query<GetOrderByIdUsingGetApiResponse, GetOrderByIdUsingGetApiArg>({
      query: queryArg => ({ url: `/v2/organizations/registration-orders/${queryArg.orderId}` }),
    }),
    cancelOrgRegistrationOrderUsingPost: build.mutation<
      CancelOrgRegistrationOrderUsingPostApiResponse,
      CancelOrgRegistrationOrderUsingPostApiArg
    >({
      query: queryArg => ({ url: `/v2/organizations/registration-orders/${queryArg.orderId}/cancel`, method: 'POST' }),
    }),
    getAssociatedEmpsUsingGet: build.query<GetAssociatedEmpsUsingGetApiResponse, GetAssociatedEmpsUsingGetApiArg>({
      query: queryArg => ({
        url: `/v2/organizations/${queryArg.cpoId}/associated-emps`,
        params: {
          modules: queryArg.modules,
          fetchPrevious: queryArg.fetchPrevious,
          limit: queryArg.limit,
          sortField: queryArg.sortField,
          sortFieldCursor: queryArg.sortFieldCursor,
          sortFieldSortOrder: queryArg.sortFieldSortOrder,
          idField: queryArg.idField,
          idFieldCursor: queryArg.idFieldCursor,
          idFieldSortOrder: queryArg.idFieldSortOrder,
          'q.name': queryArg['q.name'],
        },
      }),
    }),
    getAssociatedCposUsingGet: build.query<GetAssociatedCposUsingGetApiResponse, GetAssociatedCposUsingGetApiArg>({
      query: queryArg => ({
        url: `/v2/organizations/${queryArg.empId}/associated-cpos`,
        params: {
          modules: queryArg.modules,
          fetchPrevious: queryArg.fetchPrevious,
          limit: queryArg.limit,
          sortField: queryArg.sortField,
          sortFieldCursor: queryArg.sortFieldCursor,
          sortFieldSortOrder: queryArg.sortFieldSortOrder,
          idField: queryArg.idField,
          idFieldCursor: queryArg.idFieldCursor,
          idFieldSortOrder: queryArg.idFieldSortOrder,
          'q.name': queryArg['q.name'],
        },
      }),
    }),
    getChargingNetworkUsingGet: build.query<GetChargingNetworkUsingGetApiResponse, GetChargingNetworkUsingGetApiArg>({
      query: queryArg => ({
        url: `/v2/organizations/${queryArg.empId}/associated-cpos/charging-network`,
        params: {
          modules: queryArg.modules,
          fetchPrevious: queryArg.fetchPrevious,
          limit: queryArg.limit,
          sortField: queryArg.sortField,
          sortFieldCursor: queryArg.sortFieldCursor,
          sortFieldSortOrder: queryArg.sortFieldSortOrder,
          idField: queryArg.idField,
          idFieldCursor: queryArg.idFieldCursor,
          idFieldSortOrder: queryArg.idFieldSortOrder,
          'q.name': queryArg['q.name'],
        },
      }),
    }),
    getOrganizationUsingGet: build.query<GetOrganizationUsingGetApiResponse, GetOrganizationUsingGetApiArg>({
      query: queryArg => ({
        url: `/v2/organizations/${queryArg.organizationId}`,
        params: { resolveRoamingProviderModule: queryArg.resolveRoamingProviderModule },
      }),
    }),
    updateOrganizationUsingPut: build.mutation<UpdateOrganizationUsingPutApiResponse, UpdateOrganizationUsingPutApiArg>(
      {
        query: queryArg => ({
          url: `/v2/organizations/${queryArg.organizationId}`,
          method: 'PUT',
          body: queryArg.organizationUpdateRequest,
        }),
      },
    ),
    deleteOrganizationUsingDelete: build.mutation<
      DeleteOrganizationUsingDeleteApiResponse,
      DeleteOrganizationUsingDeleteApiArg
    >({
      query: queryArg => ({ url: `/v2/organizations/${queryArg.organizationId}`, method: 'DELETE' }),
    }),
    getAccessibleOrganizationsUsingGet: build.query<
      GetAccessibleOrganizationsUsingGetApiResponse,
      GetAccessibleOrganizationsUsingGetApiArg
    >({
      query: queryArg => ({
        url: `/v2/organizations/${queryArg.organizationId}/accessible-organizations`,
        params: {
          fetchPrevious: queryArg.fetchPrevious,
          limit: queryArg.limit,
          sortField: queryArg.sortField,
          sortFieldCursor: queryArg.sortFieldCursor,
          sortFieldSortOrder: queryArg.sortFieldSortOrder,
          idField: queryArg.idField,
          idFieldCursor: queryArg.idFieldCursor,
          idFieldSortOrder: queryArg.idFieldSortOrder,
          modules: queryArg.modules,
          'q.name': queryArg['q.name'],
          'q.externalId': queryArg['q.externalId'],
          'q.advancedSearch': queryArg['q.advancedSearch'],
        },
      }),
    }),
    makeOrganizationAccessibleUsingPost: build.mutation<
      MakeOrganizationAccessibleUsingPostApiResponse,
      MakeOrganizationAccessibleUsingPostApiArg
    >({
      query: queryArg => ({
        url: `/v2/organizations/${queryArg.organizationId}/accessible-organizations`,
        method: 'POST',
        body: queryArg.organizationAssociationCreateRequest,
      }),
    }),
    removeOrganizationAccessUsingDelete: build.mutation<
      RemoveOrganizationAccessUsingDeleteApiResponse,
      RemoveOrganizationAccessUsingDeleteApiArg
    >({
      query: queryArg => ({
        url: `/v2/organizations/${queryArg.organizationId}/accessible-organizations/${queryArg.accessibleOrganizationId}`,
        method: 'DELETE',
      }),
    }),
    getOrganizationBrandingUsingGet: build.query<
      GetOrganizationBrandingUsingGetApiResponse,
      GetOrganizationBrandingUsingGetApiArg
    >({
      query: queryArg => ({ url: `/v2/organizations/${queryArg.organizationId}/branding` }),
    }),
    updateOrganizationBrandingUsingPut: build.mutation<
      UpdateOrganizationBrandingUsingPutApiResponse,
      UpdateOrganizationBrandingUsingPutApiArg
    >({
      query: queryArg => ({
        url: `/v2/organizations/${queryArg.organizationId}/branding`,
        method: 'PUT',
        body: queryArg.orgConfigPayloadBrandingConfiguration,
      }),
    }),
    getOrganizationCountryCodesUsingGet: build.query<
      GetOrganizationCountryCodesUsingGetApiResponse,
      GetOrganizationCountryCodesUsingGetApiArg
    >({
      query: queryArg => ({ url: `/v2/organizations/${queryArg.organizationId}/countryCodes` }),
    }),
    listFleetDriversUsingGet: build.query<ListFleetDriversUsingGetApiResponse, ListFleetDriversUsingGetApiArg>({
      query: queryArg => ({
        url: `/v2/organizations/${queryArg.organizationId}/fleet-drivers`,
        params: {
          fetchPrevious: queryArg.fetchPrevious,
          limit: queryArg.limit,
          sortField: queryArg.sortField,
          sortFieldCursor: queryArg.sortFieldCursor,
          sortFieldSortOrder: queryArg.sortFieldSortOrder,
          idField: queryArg.idField,
          idFieldCursor: queryArg.idFieldCursor,
          idFieldSortOrder: queryArg.idFieldSortOrder,
        },
      }),
    }),
    getOrganizationImagesUsingGet: build.query<
      GetOrganizationImagesUsingGetApiResponse,
      GetOrganizationImagesUsingGetApiArg
    >({
      query: queryArg => ({ url: `/v2/organizations/${queryArg.organizationId}/images` }),
    }),
    inviteUserUsingPost: build.mutation<InviteUserUsingPostApiResponse, InviteUserUsingPostApiArg>({
      query: queryArg => ({
        url: `/v2/organizations/${queryArg.organizationId}/invite`,
        method: 'POST',
        params: { email: queryArg.email },
      }),
    }),
    getSchemaUsingGet: build.query<GetSchemaUsingGetApiResponse, GetSchemaUsingGetApiArg>({
      query: queryArg => ({ url: `/v2/organizations/${queryArg.organizationId}/registration-orders/schema` }),
    }),
    searchOrdersUsingPost: build.query<SearchOrdersUsingPostApiResponse, SearchOrdersUsingPostApiArg>({
      query: queryArg => ({
        url: `/v2/organizations/${queryArg.organizationId}/registration-orders/search`,
        method: 'POST',
        body: queryArg.orgRegistrationOrderSearchRequest,
        params: {
          limit: queryArg.limit,
          sortField: queryArg.sortField,
          sortFieldCursor: queryArg.sortFieldCursor,
          sortFieldSortOrder: queryArg.sortFieldSortOrder,
          idField: queryArg.idField,
          idFieldCursor: queryArg.idFieldCursor,
          idFieldSortOrder: queryArg.idFieldSortOrder,
          fetchPrevious: queryArg.fetchPrevious,
        },
      }),
    }),
    getAllSystemUsersFromEsUsingGet: build.query<
      GetAllSystemUsersFromEsUsingGetApiResponse,
      GetAllSystemUsersFromEsUsingGetApiArg
    >({
      query: queryArg => ({
        url: `/v2/organizations/${queryArg.organizationId}/system-users`,
        params: {
          'q.email': queryArg['q.email'],
          useExactEmailMatch: queryArg.useExactEmailMatch,
          fetchPrevious: queryArg.fetchPrevious,
          limit: queryArg.limit,
          sortField: queryArg.sortField,
          sortFieldCursor: queryArg.sortFieldCursor,
          sortFieldSortOrder: queryArg.sortFieldSortOrder,
          idField: queryArg.idField,
          idFieldCursor: queryArg.idFieldCursor,
          idFieldSortOrder: queryArg.idFieldSortOrder,
        },
      }),
    }),
    uploadLogoUsingPost: build.mutation<UploadLogoUsingPostApiResponse, UploadLogoUsingPostApiArg>({
      query: queryArg => ({
        url: `/v2/organizations/${queryArg.organizationId}/${queryArg.version}/image/${queryArg.imageType}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    removeOrganizationImageUsingDelete: build.mutation<
      RemoveOrganizationImageUsingDeleteApiResponse,
      RemoveOrganizationImageUsingDeleteApiArg
    >({
      query: queryArg => ({
        url: `/v2/organizations/${queryArg.organizationId}/${queryArg.version}/image/${queryArg.imageType}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as v2OrganizationsApi };
export type CreateOrganizationUsingPostApiResponse = /** status 200 OK */ Organization;
export type CreateOrganizationUsingPostApiArg = {
  /** orderId */
  orderId?: string;
  /** request */
  organizationCreateRequest: OrganizationCreateRequest;
};
export type ExportPayoutsUsingPostApiResponse = /** status 200 OK */ void;
export type ExportPayoutsUsingPostApiArg = {
  /** reportRequest */
  payoutReportRequest: PayoutReportRequest;
};
export type GetOrganizationModulesUsingGetApiResponse = /** status 200 OK */ (
  | 'EMP'
  | 'CPO'
  | 'AO'
  | 'LO'
  | 'MFR'
  | 'FLEET'
  | 'PUBLIC'
  | 'EXTERNAL_CPO'
  | 'EXTERNAL_EMP'
  | 'ROAMING_PROVIDER'
  | 'UNKNOWN'
)[];
export type GetOrganizationModulesUsingGetApiArg = void;
export type GetOrderByIdUsingGetApiResponse = /** status 200 OK */ OrgRegistrationOrderResponse;
export type GetOrderByIdUsingGetApiArg = {
  /** orderId */
  orderId: string;
};
export type CancelOrgRegistrationOrderUsingPostApiResponse = /** status 200 OK */ void;
export type CancelOrgRegistrationOrderUsingPostApiArg = {
  /** orderId */
  orderId: string;
};
export type GetAssociatedEmpsUsingGetApiResponse = /** status 200 OK */ PageResponseOrganization;
export type GetAssociatedEmpsUsingGetApiArg = {
  /** Requested organization id */
  cpoId: string;
  /** modules */
  modules?: (
    | 'EMP'
    | 'CPO'
    | 'AO'
    | 'LO'
    | 'MFR'
    | 'FLEET'
    | 'PUBLIC'
    | 'EXTERNAL_CPO'
    | 'EXTERNAL_EMP'
    | 'ROAMING_PROVIDER'
    | 'UNKNOWN'
  )[];
  /** fetchPrevious */
  fetchPrevious?: boolean;
  /** limit */
  limit?: number;
  sortField?: string;
  sortFieldCursor?: object;
  sortFieldSortOrder?: string;
  idField?: string;
  idFieldCursor?: string;
  idFieldSortOrder?: string;
  /** q.name */
  'q.name'?: string;
};
export type GetAssociatedCposUsingGetApiResponse = /** status 200 OK */ PageResponseOrganization;
export type GetAssociatedCposUsingGetApiArg = {
  /** Requested organization id */
  empId: string;
  /** modules */
  modules?: (
    | 'EMP'
    | 'CPO'
    | 'AO'
    | 'LO'
    | 'MFR'
    | 'FLEET'
    | 'PUBLIC'
    | 'EXTERNAL_CPO'
    | 'EXTERNAL_EMP'
    | 'ROAMING_PROVIDER'
    | 'UNKNOWN'
  )[];
  /** fetchPrevious */
  fetchPrevious?: boolean;
  /** limit */
  limit?: number;
  sortField?: string;
  sortFieldCursor?: object;
  sortFieldSortOrder?: string;
  idField?: string;
  idFieldCursor?: string;
  idFieldSortOrder?: string;
  /** q.name */
  'q.name'?: string;
};
export type GetChargingNetworkUsingGetApiResponse = /** status 200 OK */ PageResponseOrganization;
export type GetChargingNetworkUsingGetApiArg = {
  /** Requested organization id */
  empId: string;
  /** modules */
  modules?: (
    | 'EMP'
    | 'CPO'
    | 'AO'
    | 'LO'
    | 'MFR'
    | 'FLEET'
    | 'PUBLIC'
    | 'EXTERNAL_CPO'
    | 'EXTERNAL_EMP'
    | 'ROAMING_PROVIDER'
    | 'UNKNOWN'
  )[];
  /** fetchPrevious */
  fetchPrevious?: boolean;
  /** limit */
  limit?: number;
  sortField?: string;
  sortFieldCursor?: object;
  sortFieldSortOrder?: string;
  idField?: string;
  idFieldCursor?: string;
  idFieldSortOrder?: string;
  /** q.name */
  'q.name'?: string;
};
export type GetOrganizationUsingGetApiResponse = /** status 200 OK */ Organization;
export type GetOrganizationUsingGetApiArg = {
  /** organizationId */
  organizationId: string;
  /** resolveRoamingProviderModule */
  resolveRoamingProviderModule?: boolean;
};
export type UpdateOrganizationUsingPutApiResponse = /** status 200 OK */ Organization;
export type UpdateOrganizationUsingPutApiArg = {
  /** organizationId */
  organizationId: string;
  /** request */
  organizationUpdateRequest: OrganizationUpdateRequest;
};
export type DeleteOrganizationUsingDeleteApiResponse = /** status 200 OK */ void;
export type DeleteOrganizationUsingDeleteApiArg = {
  /** organizationId */
  organizationId: string;
};
export type GetAccessibleOrganizationsUsingGetApiResponse = /** status 200 OK */ PageResponseOrganization;
export type GetAccessibleOrganizationsUsingGetApiArg = {
  /** Requested organization id */
  organizationId: string;
  /** fetchPrevious */
  fetchPrevious?: boolean;
  /** limit */
  limit?: number;
  sortField?: string;
  sortFieldCursor?: object;
  sortFieldSortOrder?: string;
  idField?: string;
  idFieldCursor?: string;
  idFieldSortOrder?: string;
  /** modules */
  modules?: (
    | 'EMP'
    | 'CPO'
    | 'AO'
    | 'LO'
    | 'MFR'
    | 'FLEET'
    | 'PUBLIC'
    | 'EXTERNAL_CPO'
    | 'EXTERNAL_EMP'
    | 'ROAMING_PROVIDER'
    | 'UNKNOWN'
  )[];
  /** q.name */
  'q.name'?: string;
  /** q.externalId */
  'q.externalId'?: string;
  /** q.advancedSearch */
  'q.advancedSearch'?: string;
};
export type MakeOrganizationAccessibleUsingPostApiResponse = /** status 200 OK */ Organization;
export type MakeOrganizationAccessibleUsingPostApiArg = {
  /** Requested organization id */
  organizationId: string;
  /** request */
  organizationAssociationCreateRequest: OrganizationAssociationCreateRequest;
};
export type RemoveOrganizationAccessUsingDeleteApiResponse = /** status 200 OK */ void;
export type RemoveOrganizationAccessUsingDeleteApiArg = {
  /** Requested organization id */
  organizationId: string;
  /** The id of the organization the access is for */
  accessibleOrganizationId: string;
};
export type GetOrganizationBrandingUsingGetApiResponse = /** status 200 OK */ OrgConfigPayloadBrandingConfiguration;
export type GetOrganizationBrandingUsingGetApiArg = {
  /** organizationId */
  organizationId: string;
};
export type UpdateOrganizationBrandingUsingPutApiResponse = /** status 200 OK */ OrgConfigPayloadBrandingConfiguration;
export type UpdateOrganizationBrandingUsingPutApiArg = {
  /** organizationId */
  organizationId: string;
  /** brandingConfiguration */
  orgConfigPayloadBrandingConfiguration: OrgConfigPayloadBrandingConfiguration;
};
export type GetOrganizationCountryCodesUsingGetApiResponse = /** status 200 OK */ string[];
export type GetOrganizationCountryCodesUsingGetApiArg = {
  /** organizationId */
  organizationId: string;
};
export type ListFleetDriversUsingGetApiResponse = /** status 200 OK */ PageResponseCustomer;
export type ListFleetDriversUsingGetApiArg = {
  /** organizationId */
  organizationId: string;
  /** fetchPrevious */
  fetchPrevious?: boolean;
  /** limit */
  limit?: number;
  sortField?: string;
  sortFieldCursor?: object;
  sortFieldSortOrder?: string;
  idField?: string;
  idFieldCursor?: string;
  idFieldSortOrder?: string;
};
export type GetOrganizationImagesUsingGetApiResponse = /** status 200 OK */ OrgConfigPayloadImagesConfiguration;
export type GetOrganizationImagesUsingGetApiArg = {
  /** organizationId */
  organizationId: string;
};
export type InviteUserUsingPostApiResponse = /** status 200 OK */ {
  [key: string]: string;
};
export type InviteUserUsingPostApiArg = {
  /** organizationId */
  organizationId: string;
  /** email */
  email: string;
};
export type GetSchemaUsingGetApiResponse = /** status 200 OK */ object;
export type GetSchemaUsingGetApiArg = {
  /** organizationId */
  organizationId: string;
};
export type SearchOrdersUsingPostApiResponse = /** status 200 OK */ PageResponseOrgRegistrationOrderResponseLite;
export type SearchOrdersUsingPostApiArg = {
  /** limit */
  limit?: number;
  sortField?: string;
  sortFieldCursor?: object;
  sortFieldSortOrder?: string;
  idField?: string;
  idFieldCursor?: string;
  idFieldSortOrder?: string;
  /** fetchPrevious */
  fetchPrevious?: boolean;
  /** organizationId */
  organizationId: string;
  /** searchRequest */
  orgRegistrationOrderSearchRequest: OrgRegistrationOrderSearchRequest;
};
export type GetAllSystemUsersFromEsUsingGetApiResponse = /** status 200 OK */ PageResponseSystemUser;
export type GetAllSystemUsersFromEsUsingGetApiArg = {
  /** organizationId */
  organizationId: string;
  /** q.email */
  'q.email'?: string;
  /** useExactEmailMatch */
  useExactEmailMatch?: boolean;
  /** fetchPrevious */
  fetchPrevious?: boolean;
  /** limit */
  limit?: number;
  sortField?: string;
  sortFieldCursor?: object;
  sortFieldSortOrder?: string;
  idField?: string;
  idFieldCursor?: string;
  idFieldSortOrder?: string;
};
export type UploadLogoUsingPostApiResponse = /** status 200 OK */ OrgConfigPayloadImagesConfiguration;
export type UploadLogoUsingPostApiArg = {
  /** organizationId */
  organizationId: string;
  /** version */
  version: number;
  /** imageType */
  imageType: 'SQUARE' | 'PORTRAIT' | 'LANDSCAPE';
  body: {
    /** file */
    file: Blob;
  };
};
export type RemoveOrganizationImageUsingDeleteApiResponse = /** status 200 OK */ OrgConfigPayloadImagesConfiguration;
export type RemoveOrganizationImageUsingDeleteApiArg = {
  /** organizationId */
  organizationId: string;
  /** version */
  version: number;
  /** imageType */
  imageType: 'SQUARE' | 'PORTRAIT' | 'LANDSCAPE';
};
export type OrganisationContact = {
  email: string;
  firstName: string;
  lastName: string;
  notes: string;
  phoneNumber: string;
  type: 'Technical' | 'Financial' | 'Commercial';
};
export type Address = any;
export type Contact = {
  email: string;
  firstName: string;
  lastName: string;
  notes: string;
  phoneNumber: string;
};
export type DisplayName = {
  autoGenerated: boolean;
  displayName: string;
};
export type Image = {
  bucket: string;
  key: string;
  type: 'SQUARE' | 'PORTRAIT' | 'LANDSCAPE';
};
export type OrganizationModule = {
  abbreviation:
    | 'EMP'
    | 'CPO'
    | 'AO'
    | 'LO'
    | 'MFR'
    | 'FLEET'
    | 'PUBLIC'
    | 'EXTERNAL_CPO'
    | 'EXTERNAL_EMP'
    | 'ROAMING_PROVIDER'
    | 'UNKNOWN';
  id: string;
  name: string;
  type: 'NONE' | 'B2B';
  created?: number;
  updated?: number;
  version?: number;
};
export type ParentOrganization = {
  id: string;
  modules: OrganizationModule[];
  name: string;
};
export type Organization = {
  additionalContacts: OrganisationContact[];
  billingAddress?: Address;
  billingPhoneNumber?: string;
  brandingConfig?: {
    [key: string]: string;
  };
  contact?: Contact;
  countryCode?: string;
  created: number;
  displayName: DisplayName;
  domain?: string;
  email?: string;
  emi3Id?: string;
  externalOrganizationId?: string;
  heliosNumber?: string;
  hotline?: string;
  id: string;
  images?: Image[];
  isGireveEnabled?: boolean;
  language?: string;
  modules: OrganizationModule[];
  name: string;
  organizationNumber?: string;
  parentId?: string;
  parentOrganization?: ParentOrganization;
  phoneNumber?: string;
  siret?: string;
  updated: number;
  userPrefix: string;
  vat?: string;
  version: number;
  visitingAddress?: Address;
};
export type FieldConstraintViolation = {
  errorKey?:
    | 'MUST_BE_HIGHER'
    | 'MUST_BE_LOWER'
    | 'MUST_BE_NEGATIVE'
    | 'MUST_BE_NEGATIVE_OR_ZERO'
    | 'MUST_BE_POSITIVE'
    | 'MUST_BE_POSITIVE_OR_ZERO'
    | 'MUST_BE_UNIQUE'
    | 'MUST_BE_VALID'
    | 'MUST_BE_WITHIN_RANGE'
    | 'MUST_NOT_BE_EMPTY'
    | 'MUST_NOT_BE_NULL'
    | 'MUST_NOT_BE_NULL_OR_EMPTY'
    | 'MUST_NOT_BE_ZERO';
  field?: string;
  message?: string;
  rejectedValue?: object;
};
export type ErrorResponse = {
  code?: number;
  details?: string[];
  fieldViolations?: FieldConstraintViolation[];
  message?: string;
  status?: string;
  statusCode?: number;
};
export type ContactWithType = {
  type: 'Technical' | 'Financial' | 'Commercial';
  firstName: string;
  lastName: string;
  email: string;
  notes: string;
  phoneNumber: string;
};
export type ContractIdConfiguration = {
  defaultContractIdPrefix: string;
  useContractId: boolean;
  allowedContractIdPrefixes: string[];
  plugAndChargeContractIdPrefix: string;
};
export type OrganizationCreateRequest = {
  parentOrganizationId: string;
  name: string;
  domain: string;
  organizationNumber: string;
  email: string;
  vat: string;
  moduleIds: string[];
  billingAddress: Address;
  visitingAddress: Address;
  phoneNumber: string;
  billingPhoneNumber: string;
  contact: Contact;
  additionalContacts: ContactWithType[];
  siret: string;
  heliosNumber: string;
  countryCode: string;
  language: string;
  isGireveEnabled: boolean;
  emi3Id: string;
  externalOrganizationId: string;
  displayName: string;
  contractIdConfiguration: ContractIdConfiguration;
  hotline: string;
};
export type PayoutReportRequest = {
  /** from date for the report in epoch milliseconds */
  fromDate?: number;
  /** Organization id to base the report on */
  organizationId?: string;
  /** Email to send the report to */
  recipientEmail?: string;
  /** Type of the report to be generated */
  requestType: 'REFUND' | 'PAYOUT';
  /** to date for the report in epoch milliseconds */
  toDate?: number;
};
export type OrgRegistrationOrderResponse = {
  /** Order creation date */
  createdAt: number;
  /** Id of the created organization if it is created */
  createdOrganizationId: string;
  /** Id of the organization registration order */
  id: string;
  /** Id of the parent organization */
  parentId: string;
  /** Data itself structured according to the provided schema */
  payload: object;
  /** The status of the registration order */
  status: 'OPEN' | 'CREATED' | 'REJECTED';
};
export type AbstractPagingCursor = {
  idField: string;
  idFieldCursor?: object;
  idFieldSortOrder: string;
  sortField: string;
  sortFieldCursor?: object;
  sortFieldSortOrder: string;
};
export type Pagination = {
  next?: (any | null) | AbstractPagingCursor;
  prev?: (any | null) | AbstractPagingCursor;
};
export type PageResponseOrganization = {
  content: Organization[];
  pagination: Pagination;
  totalItems: number;
};
export type OrganizationUpdateRequest = {
  parentOrganizationId?: string;
  name: string;
  version: number;
  domain?: string;
  organizationNumber?: string;
  email?: string;
  vat?: string;
  moduleIds?: string[];
  billingAddress?: Address;
  visitingAddress?: Address;
  phoneNumber?: string;
  billingPhoneNumber?: string;
  contact?: Contact;
  additionalContacts: ContactWithType[];
  siret?: string;
  heliosNumber?: string;
  countryCode?: string;
  language?: string;
  isGireveEnabled?: boolean;
  emi3Id?: string;
  externalOrganizationId?: string;
  displayName: string;
  hotline?: string;
};
export type OrganizationAssociationCreateRequest = {
  otherOrgId: string;
};
export type BrandingConfiguration = {
  colors: {
    [key: string]: string;
  };
};
export type OrgConfigPayloadBrandingConfiguration = {
  config: BrandingConfiguration;
  version: number;
};
export type GenericAddress = {
  additionalInformation: string;
  address: string;
  addressLine2: string;
  chargingCapability: boolean;
  city: string;
  companyName: string;
  country: string;
  firstName: string;
  id: string;
  lastName: string;
  name: string;
  postOfficeBox: string;
  postalCode: string;
  type: 'HOME' | 'WORK' | 'OTHER';
};
export type BillingAddress = {
  additionalInformation: string;
  address: string;
  addressLine2: string;
  billingEmail: string;
  city: string;
  companyName: string;
  country: string;
  firstName: string;
  lastName: string;
  name: string;
  postalCode: string;
};
export type MarketingConsentSettings = {
  internal: ('EMAIL' | 'PUSH')[];
  external: ('EMAIL' | 'PUSH')[];
  approvedFeatureAnnouncements: string[];
};
export type OrganizationBase = {
  id: string;
  name: string;
};
export type PhoneNumber = {
  name: string;
  phoneNumber: string;
};
export type UserGroupWithOrganizationId = {
  allowedMemberDurationInDays?: number;
  approvedAssetOwnerOrganizationId?: string;
  /** The date that this entity was created, formatted as epoch (ms) */
  created?: number;
  description?: string;
  /** The model id */
  id: string;
  memberSinceDate?: number;
  membersCount?: number;
  name: string;
  nextGroupId?: string;
  organizationId: string;
  /** The date that this entity was last modified, formatted as epoch (ms) */
  updated?: number;
  /** The entity version, used for optimistic locking. */
  version?: number;
};
export type Customer = {
  addresses: GenericAddress[];
  billingAddress: BillingAddress;
  countryCode: string;
  created: number;
  customFields: object;
  customerType: 'PRIVATE' | 'BUSINESS';
  email: string;
  evModelIds: string[];
  externalId: string;
  fleetIds: string[];
  id: string;
  language:
    | 'undefined'
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu';
  lastActive: number;
  licensePlates: string[];
  marketingConsent: MarketingConsentSettings;
  organization: OrganizationBase;
  phoneNumber: string;
  phoneNumbers: PhoneNumber[];
  status: 'ACTIVE' | 'PENDING_DELETION' | 'DELETION_FAILED_DEBT' | 'OTHER_DELETION_ERROR';
  systemUser: boolean;
  updated: number;
  userGroups: UserGroupWithOrganizationId[];
  vatNumber: string;
  verifiedPhoneNumber: string;
  version: number;
};
export type PageResponseCustomer = {
  content: Customer[];
  pagination: Pagination;
  totalItems: number;
};
export type ImagesConfiguration = {
  images: {
    [key: string]: Image;
  };
};
export type OrgConfigPayloadImagesConfiguration = {
  config: ImagesConfiguration;
  version: number;
};
export type OrgRegistrationOrderResponseLite = {
  /** Order creation date */
  createdAt: number;
  /** Id of the organization registration order */
  id: string;
  /** Name of the organization */
  name: string;
  /** Id of the parent organization */
  parentId: string;
  /** Status of the order */
  status: 'OPEN' | 'CREATED' | 'REJECTED';
  /** Type of the organization */
  type: 'PUBLIC' | 'B2B';
};
export type PageResponseOrgRegistrationOrderResponseLite = {
  content: OrgRegistrationOrderResponseLite[];
  pagination: Pagination;
  totalItems: number;
};
export type OrgRegistrationOrderSearchRequest = {
  organizationName: string;
  orderStatus: string;
};
export type PhoneNumberV2 = {
  /** Name to distinguish different phone numbers */
  name?: string;
  /** The actual phone number */
  phoneNumber?: string;
};
export type Role = {
  /** The date that this entity was created, formatted as epoch (ms) */
  created: number;
  /** The role description */
  description?: string;
  /** The model id */
  id: string;
  /** The role name */
  name?: string;
  /** A list of privileges that belong to this role */
  privileges?: (
    | 'ADHOC_CONFIG_DELETE'
    | 'ADHOC_CONFIG_READ'
    | 'ADHOC_CONFIG_UPDATE'
    | 'AUTH_RESULT_READ'
    | 'BANK_TRANSFER_CREATE'
    | 'BANK_TRANSFER_UPDATE'
    | 'BOTEN_ANNA'
    | 'CAMPAIGN_CREATE'
    | 'CAMPAIGN_DELETE'
    | 'CAMPAIGN_READ'
    | 'CAMPAIGN_UPDATE'
    | 'CDR_READ'
    | 'CDR_RETRY'
    | 'CDR_UPLOAD'
    | 'CHARGE_POINT_COMMAND_CLEAR_AUTHORIZATION_LIST'
    | 'CHARGE_POINT_COMMAND_CLEAR_CACHE'
    | 'CHARGE_POINT_COMMAND_GET_DIAGNOSTICS'
    | 'CHARGE_POINT_COMMAND_RESET'
    | 'CHARGE_POINT_COMMAND_STOP'
    | 'CHARGE_POINT_COMMAND_SYNCHRONIZE_CONFIGURATION'
    | 'CHARGE_POINT_COMMAND_UNLOCK'
    | 'CHARGE_POINT_COMMAND_UPDATE_AUTHORIZATION_LIST'
    | 'CHARGE_POINT_COMMAND_UPDATE_FIRMWARE'
    | 'CHARGE_POINT_CONNECTION_PROFILE_CREATE'
    | 'CHARGE_POINT_CONNECTION_PROFILE_DELETE'
    | 'CHARGE_POINT_CONNECTION_PROFILE_READ'
    | 'CHARGE_POINT_CONNECTION_PROFILE_UPDATE'
    | 'CHARGE_POINT_CREATE'
    | 'CHARGE_POINT_DELETE'
    | 'CHARGE_POINT_FIRMWARE_CREATE'
    | 'CHARGE_POINT_FIRMWARE_DELETE'
    | 'CHARGE_POINT_FIRMWARE_READ'
    | 'CHARGE_POINT_FIRMWARE_UPDATE'
    | 'CHARGE_POINT_LOG_READ'
    | 'CHARGE_POINT_MODEL_CREATE'
    | 'CHARGE_POINT_MODEL_DELETE'
    | 'CHARGE_POINT_MODEL_READ'
    | 'CHARGE_POINT_MODEL_UPDATE'
    | 'CHARGE_POINT_NOTIFICATION_PROFILE_CREATE'
    | 'CHARGE_POINT_NOTIFICATION_PROFILE_DELETE'
    | 'CHARGE_POINT_NOTIFICATION_PROFILE_READ'
    | 'CHARGE_POINT_NOTIFICATION_PROFILE_UPDATE'
    | 'CHARGE_POINT_READ'
    | 'CHARGE_POINT_REPORTING_READ'
    | 'CHARGE_POINT_REPORTING_SENSITIVE_DATA_READ'
    | 'CHARGE_POINT_SITE_CREATE'
    | 'CHARGE_POINT_SITE_DELETE'
    | 'CHARGE_POINT_SITE_READ'
    | 'CHARGE_POINT_SITE_UPDATE'
    | 'CHARGE_POINT_UPDATE'
    | 'CHARGING_AUTHORIZATION_GROUP_CREATE'
    | 'CHARGING_AUTHORIZATION_GROUP_DELETE'
    | 'CHARGING_AUTHORIZATION_GROUP_READ'
    | 'CHARGING_AUTHORIZATION_GROUP_UPDATE'
    | 'CHARGING_RESERVATION_CREATE'
    | 'CHARGING_RESERVATION_DELETE'
    | 'CHARGING_RESERVATION_READ'
    | 'CHARGING_SESSIONS_CLEAR'
    | 'CHARGING_SESSIONS_CREATE'
    | 'CHARGING_SESSIONS_READ'
    | 'CHARGING_SESSIONS_UPDATE'
    | 'CONNECTOR_GROUPS_GROUPS_CREATE'
    | 'CONNECTOR_GROUPS_GROUPS_DELETE'
    | 'CONNECTOR_GROUPS_GROUPS_READ'
    | 'CONNECTOR_GROUPS_GROUPS_UPDATE'
    | 'CONNECTOR_GROUPS_RULES_CREATE'
    | 'CONNECTOR_GROUPS_RULES_DELETE'
    | 'CONNECTOR_GROUPS_RULES_READ'
    | 'CONNECTOR_GROUP_CREATE'
    | 'CONNECTOR_GROUP_DELETE'
    | 'CONNECTOR_GROUP_READ'
    | 'CONNECTOR_GROUP_UPDATE'
    | 'CONNECTOR_PRICE_CREATE'
    | 'CONNECTOR_PRICE_DELETE'
    | 'CONNECTOR_PRICE_READ'
    | 'CONNECTOR_PRICE_UPDATE'
    | 'CREDIT_CARD_RESERVATION_ENABLE'
    | 'CREDIT_CARD_RESERVATION_READ'
    | 'CREDIT_CARD_RESERVATION_UPDATE'
    | 'CUSTOMER_CREATE'
    | 'CUSTOMER_DELETE'
    | 'CUSTOMER_INVOICE_CREATE'
    | 'CUSTOMER_INVOICE_READ'
    | 'CUSTOMER_INVOICE_UPDATE'
    | 'CUSTOMER_KEY_CREATE'
    | 'CUSTOMER_KEY_DELETE'
    | 'CUSTOMER_KEY_FOLD_OUT_READ'
    | 'CUSTOMER_KEY_ORDER_CREATE'
    | 'CUSTOMER_KEY_ORDER_DELETE'
    | 'CUSTOMER_KEY_ORDER_READ'
    | 'CUSTOMER_KEY_ORDER_UPDATE'
    | 'CUSTOMER_KEY_READ'
    | 'CUSTOMER_KEY_RESERVE'
    | 'CUSTOMER_KEY_UPDATE'
    | 'CUSTOMER_PAYMENT_METHODS_CREATE'
    | 'CUSTOMER_PAYMENT_METHODS_DELETE'
    | 'CUSTOMER_PAYMENT_METHODS_READ'
    | 'CUSTOMER_PAYMENT_METHODS_UPDATE'
    | 'CUSTOMER_READ'
    | 'CUSTOMER_SEARCH'
    | 'CUSTOMER_TOP_UP_PREPAID_UPDATE'
    | 'CUSTOMER_UPDATE'
    | 'DEVELOPER_PAGE_LOGS_READ'
    | 'DEVELOPER_PAGE_OVERVIEW_READ'
    | 'DEVELOPER_PAGE_WEBHOOKS_MANAGE'
    | 'DEVELOPER_PAGE_WEBHOOKS_READ'
    | 'DISCOUNT_READ'
    | 'DISCOUNT_WRITE'
    | 'EMP_CPO_ASSOCIATION_READ'
    | 'EMP_CPO_ASSOCIATION_WRITE'
    | 'ERROR_SCENARIO_READ'
    | 'ERROR_SCENARIO_WRITE'
    | 'EVSE_MAPPING_CREATE'
    | 'EVSE_MAPPING_DELETE'
    | 'EVSE_MAPPING_READ'
    | 'EVSE_MAPPING_UPDATE'
    | 'EV_MODELS_CREATE'
    | 'EV_MODELS_DELETE'
    | 'EV_MODELS_READ'
    | 'EXTERNAL_CHARGE_POINT_DELETE'
    | 'EXTERNAL_CHARGE_POINT_READ'
    | 'EXTERNAL_CHARGE_POINT_WRITE'
    | 'EXTERNAL_LOCATION_DELETE'
    | 'EXTERNAL_LOCATION_READ'
    | 'EXTERNAL_LOCATION_WRITE'
    | 'EXTERNAL_PAYMENT_METHOD_CREATE'
    | 'EXTERNAL_PAYMENT_METHOD_DELETE'
    | 'EXTERNAL_ROAMING_INTEGRATIONS_CREATE'
    | 'EXTERNAL_ROAMING_INTEGRATIONS_DELETE'
    | 'EXTERNAL_ROAMING_INTEGRATIONS_READ'
    | 'EXTERNAL_ROAMING_INTEGRATIONS_UPDATE'
    | 'EXTERNAL_TICKETS_CREATE'
    | 'EXTERNAL_TICKETS_READ'
    | 'EXTERNAL_TICKET_TRACKER_CONFIG_CREATE'
    | 'EXTERNAL_TICKET_TRACKER_CONFIG_DELETE'
    | 'EXTERNAL_TICKET_TRACKER_CONFIG_READ'
    | 'EXTERNAL_TICKET_TRACKER_CONFIG_UPDATE'
    | 'FIRMWARE_ERROR_CODE_CREATE'
    | 'FIRMWARE_ERROR_CODE_DELETE'
    | 'FIRMWARE_ERROR_CODE_READ'
    | 'FIRMWARE_ERROR_CODE_UPDATE'
    | 'FLEET_FEE_CREATE'
    | 'FLEET_FEE_DELETE'
    | 'FLEET_FEE_READ'
    | 'FLEET_MEMBER_INVITE_CREATE'
    | 'FLEET_MEMBER_INVITE_DELETE'
    | 'FLEET_MEMBER_INVITE_READ'
    | 'FLEET_MEMBER_INVITE_UPDATE'
    | 'FLEET_PAYMENT_METHOD_CREATE'
    | 'FLEET_PAYMENT_METHOD_DELETE'
    | 'FLEET_PAYMENT_METHOD_READ'
    | 'FLEET_PAYMENT_METHOD_UPDATE'
    | 'GENERAL_EXPORT_READ'
    | 'HOME_CHARGE_POINT_RELATION_CREATE'
    | 'HOME_CHARGE_POINT_RELATION_DELETE'
    | 'HOME_CHARGE_POINT_RELATION_READ'
    | 'INVOICE_RECONCILIATION_CREATE'
    | 'ISSUE_TRACKER_CREATE'
    | 'ISSUE_TRACKER_DELETE'
    | 'ISSUE_TRACKER_READ'
    | 'ISSUE_TRACKER_UPDATE'
    | 'LOCATION_LIST_INTERNAL_EXTERNAL'
    | 'MANAGE_PLATFORM'
    | 'MARKETPLACE_AGREEMENT_CREATE'
    | 'MARKETPLACE_AGREEMENT_READ'
    | 'MARKETPLACE_AGREEMENT_UPDATE'
    | 'MFA_RESET'
    | 'OCPI_INTEGRATION_CREATE'
    | 'OCPI_INTEGRATION_DELETE'
    | 'OCPI_INTEGRATION_READ'
    | 'OCPI_INTEGRATION_UPDATE'
    | 'ORGANIZATION_ACCEPTED_PAYMENT_METHOD_CREATE'
    | 'ORGANIZATION_ACCEPTED_PAYMENT_METHOD_READ'
    | 'ORGANIZATION_ACCESSIBLE_CREATE'
    | 'ORGANIZATION_ACCESSIBLE_DELETE'
    | 'ORGANIZATION_ACCESSIBLE_READ'
    | 'ORGANIZATION_AGREEMENT_CREATE'
    | 'ORGANIZATION_AGREEMENT_DELETE'
    | 'ORGANIZATION_AGREEMENT_READ'
    | 'ORGANIZATION_AGREEMENT_UPDATE'
    | 'ORGANIZATION_BRANDING_DELETE'
    | 'ORGANIZATION_BRANDING_READ'
    | 'ORGANIZATION_BRANDING_UPDATE'
    | 'ORGANIZATION_CHARGING_SESSIONS_EXPORT_READ'
    | 'ORGANIZATION_CKR_IMPORT_CREATE'
    | 'ORGANIZATION_CKR_READ'
    | 'ORGANIZATION_CONFIG_CREATE'
    | 'ORGANIZATION_CONFIG_DELETE'
    | 'ORGANIZATION_CONFIG_READ'
    | 'ORGANIZATION_CONFIG_UPDATE'
    | 'ORGANIZATION_CREATE'
    | 'ORGANIZATION_DELETE'
    | 'ORGANIZATION_ELWIN_ACCOUNT_CREATE'
    | 'ORGANIZATION_FINANCIAL_TRANSACTION_READ'
    | 'ORGANIZATION_GRAPHICS_CREATE'
    | 'ORGANIZATION_GRAPHICS_DELETE'
    | 'ORGANIZATION_GRAPHICS_READ'
    | 'ORGANIZATION_GRAPHICS_UPDATE'
    | 'ORGANIZATION_PARTNER_CREATE'
    | 'ORGANIZATION_PARTNER_DELETE'
    | 'ORGANIZATION_PARTNER_READ'
    | 'ORGANIZATION_PARTNER_UPDATE'
    | 'ORGANIZATION_READ'
    | 'ORGANIZATION_REGISTRATION_CREATE'
    | 'ORGANIZATION_REGISTRATION_DELETE'
    | 'ORGANIZATION_REGISTRATION_READ'
    | 'ORGANIZATION_REGISTRATION_UPDATE'
    | 'ORGANIZATION_STRIPE_ACCOUNT_CREATE'
    | 'ORGANIZATION_STRIPE_ACCOUNT_DELETE'
    | 'ORGANIZATION_STRIPE_ACCOUNT_READ'
    | 'ORGANIZATION_TECHNICAL_LINK_CREATE'
    | 'ORGANIZATION_TECHNICAL_LINK_DELETE'
    | 'ORGANIZATION_TECHNICAL_LINK_READ'
    | 'ORGANIZATION_TECHNICAL_LINK_UPDATE'
    | 'ORGANIZATION_TEMPLATE_CREATE'
    | 'ORGANIZATION_TEMPLATE_DELETE'
    | 'ORGANIZATION_TEMPLATE_READ'
    | 'ORGANIZATION_TEMPLATE_UPDATE'
    | 'ORGANIZATION_UPDATE'
    | 'ORG_INVOICING_READ'
    | 'ORG_INVOICING_WRITE'
    | 'PARENT_EMP_CKR_READ'
    | 'PARENT_EMP_CKR_WRITE'
    | 'PARTNER_CREATE'
    | 'PARTNER_DELETE'
    | 'PARTNER_READ'
    | 'PARTNER_UPDATE'
    | 'PAYOUT_REPORT_READ'
    | 'PRICE_ASSOCIATION_BULK_WRITE'
    | 'PRICE_PROFILE_BULK_WRITE'
    | 'PRIVACY_POLICY_CREATE'
    | 'PRIVACY_POLICY_DELETE'
    | 'PRIVACY_POLICY_READ'
    | 'PRIVACY_POLICY_UPDATE'
    | 'PRODUCT_CREATE'
    | 'PRODUCT_DELETE'
    | 'PRODUCT_READ'
    | 'PRODUCT_UPDATE'
    | 'PUBLISHING_CHANNEL_READ'
    | 'PUBLISHING_CHANNEL_SET'
    | 'PUSH_TOKEN_ON_DEMAND'
    | 'RAW_TARIFF_DATA_READ'
    | 'READ_INTERNAL_ENTITY_ID'
    | 'REMOTE_START_CONFIG_CREATE'
    | 'REMOTE_START_CONFIG_DELETE'
    | 'REMOTE_START_CONFIG_READ'
    | 'REMOTE_START_CONFIG_UPDATE'
    | 'REPORTED_CHARGER_PROBLEM_READ'
    | 'SESSION_CLASSIFICATION_RULES_CREATE'
    | 'SESSION_CLASSIFICATION_RULES_DELETE'
    | 'SESSION_CLASSIFICATION_RULES_READ'
    | 'SMART_CHARGING_CREATE'
    | 'SMART_CHARGING_DELETE'
    | 'SMART_CHARGING_READ'
    | 'SMART_CHARGING_UPDATE'
    | 'SMS_LOG_READ'
    | 'STRIPE_LINK_READ'
    | 'SUBSCRIPTION_CREATE'
    | 'SUBSCRIPTION_DELETE'
    | 'SUBSCRIPTION_READ'
    | 'SUBSCRIPTION_UPDATE'
    | 'SYSTEM_API_KEY_CREATE'
    | 'SYSTEM_API_KEY_READ'
    | 'SYSTEM_API_KEY_UPDATE'
    | 'SYSTEM_USER_CREATE'
    | 'SYSTEM_USER_DELETE'
    | 'SYSTEM_USER_READ'
    | 'SYSTEM_USER_UPDATE'
    | 'TAXATION_READ'
    | 'TAXATION_WRITE'
    | 'TEMPLATE_CREATE'
    | 'TEMPLATE_DELETE'
    | 'TEMPLATE_READ'
    | 'TEMPLATE_UPDATE'
    | 'TERMS_CREATE'
    | 'TERMS_DELETE'
    | 'TERMS_READ'
    | 'TERMS_UPDATE'
    | 'USER_GROUPS_ADD_ORGANIZATION'
    | 'USER_GROUPS_ADD_USER'
    | 'USER_GROUPS_CREATE'
    | 'USER_GROUPS_DELETE'
    | 'USER_GROUPS_READ'
    | 'USER_GROUPS_REMOVE_ORGANIZATION'
    | 'USER_GROUPS_REMOVE_USER'
    | 'USER_GROUPS_UPDATE'
    | 'NOT_MAPPABLE'
  )[];
  /** The date that this entity was last modified, formatted as epoch (ms) */
  updated: number;
};
export type SystemUser = {
  addresses: GenericAddress[];
  billingAddress: BillingAddress;
  /** The date that this entity was created, formatted as epoch (ms) */
  created: number;
  email: string;
  /** The first name of the system user */
  firstName?: string;
  /** The model id */
  id: string;
  language:
    | 'undefined'
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu';
  lastActive: number;
  /** The last name of the system user */
  lastName?: string;
  organization: Organization;
  phoneNumber: string;
  phoneNumbers: PhoneNumberV2[];
  /** A list of roles assigned to the system user */
  roles?: Role[];
  /** The status of the user */
  status?: 'ACTIVE' | 'PENDING_DELETION' | 'DELETION_FAILED_DEBT' | 'OTHER_DELETION_ERROR';
  /** The date that this entity was last modified, formatted as epoch (ms) */
  updated: number;
  version: number;
};
export type PageResponseSystemUser = {
  content: SystemUser[];
  pagination: Pagination;
  totalItems: number;
};
export const {
  useCreateOrganizationUsingPostMutation,
  useExportPayoutsUsingPostMutation,
  useGetOrganizationModulesUsingGetQuery,
  useGetOrderByIdUsingGetQuery,
  useCancelOrgRegistrationOrderUsingPostMutation,
  useGetAssociatedEmpsUsingGetQuery,
  useGetAssociatedCposUsingGetQuery,
  useGetChargingNetworkUsingGetQuery,
  useGetOrganizationUsingGetQuery,
  useUpdateOrganizationUsingPutMutation,
  useDeleteOrganizationUsingDeleteMutation,
  useGetAccessibleOrganizationsUsingGetQuery,
  useMakeOrganizationAccessibleUsingPostMutation,
  useRemoveOrganizationAccessUsingDeleteMutation,
  useGetOrganizationBrandingUsingGetQuery,
  useUpdateOrganizationBrandingUsingPutMutation,
  useGetOrganizationCountryCodesUsingGetQuery,
  useListFleetDriversUsingGetQuery,
  useGetOrganizationImagesUsingGetQuery,
  useInviteUserUsingPostMutation,
  useGetSchemaUsingGetQuery,
  useSearchOrdersUsingPostQuery,
  useGetAllSystemUsersFromEsUsingGetQuery,
  useUploadLogoUsingPostMutation,
  useRemoveOrganizationImageUsingDeleteMutation,
} = injectedRtkApi;
