import { combineEpics } from 'redux-observable';
import chargingStations from './charging-stations/epics';
import sessionHistory from './elastic-data/epics';
import electricVehicles from './electric-vehicles/epics';
import entities from './entities/epics';
import firmwares from './firmwares/epics';
import invoices from './invoices/epics';
import map from './map/epics';
import schemas from './objects-schemas/epics';
import organizationOrders from './organization-orders/epics';
import organizations from './organizations/epics';
import subscriptionsV2 from './subscriptionsV2/epics';
import userGroups from './user-groups/epics';
import users from './users/epics';

export default combineEpics(
  map,
  sessionHistory,
  userGroups,
  organizations,
  users,
  entities,
  chargingStations,
  electricVehicles,
  invoices,
  firmwares,
  schemas,
  organizationOrders,
  subscriptionsV2,
);
